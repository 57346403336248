
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Form",
    },
    data: {
      type: Object,
    },
  },
  setup(props) {
    const form = ref(props.data);

    return {
      form,
    };
  },
});
