
import Form from './Form.vue'
import { useStore } from 'vuex'
import { defineComponent, ref, onMounted, computed, toRefs, reactive } from 'vue'

export default defineComponent({
  components: {
    Form
  },
  setup () {
    const form = reactive({
      id: '',
      cluster_code: '',
      cluster_name: '',
    })
    const form_action = ref('save')
    const visible = ref(false)
    const store = useStore()

    const fields = ref([
      {
        title: 'Cluster Code',
        dataIndex: 'cluster_code',
      },
      {
        title: 'Cluster Name',
        dataIndex: 'cluster_name',
      },
      // {
      //   title: "Is Active",
      //   dataIndex: "is_active",
      //   align: 'center',
      //   slots: { customRender: "is_active" }
      // },
      {
        title: 'Action',
        dataIndex: 'action',
        slots: {customRender: 'action'},
        align: 'center'
      },
    ])

    const index = (payload = {page: 1}) => {
      store.dispatch('Cluster/index', payload)
    }

    const editRecord = (record: any) => {
      form_action.value = 'update'
      const {id, cluster_code, cluster_name} = toRefs(form)
      id.value = record.id
      cluster_code.value = record.cluster_code
      cluster_name.value = record.cluster_name
      visible.value = true
    }

    const createRecord = () => {
      form_action.value = 'save'
      const {id, cluster_code, cluster_name} = toRefs(form)
      id.value = ''
      cluster_code.value = ''
      cluster_name.value = ''
      visible.value = true
    }

    const deleteRecord = (record: any) => {
      store.dispatch('Cluster/destroy', record.id)
    }

    const handleSaving = (record: any) => {
      form_action.value == 'save' ? storeRecord(record) : updateRecord(record)
    }

    const storeRecord = (payload = {}) => {
      store
          .dispatch('Cluster/store', payload)
          .then(() => (visible.value = false))
    }

    const updateRecord = (payload = {}) => {
      store
          .dispatch('Cluster/update', payload)
          .then(() => (visible.value = false))
    }

    const filterRecord = (payload: any) => {
      index(payload)
    }

    const items = computed(() => store.getters['Cluster/items'])
    const loading = computed(() => store.getters['Cluster/status'])

    onMounted(index)

    return {
      loading,
      items,
      fields,
      visible,
      form,
      editRecord,
      deleteRecord,
      storeRecord,
      createRecord,
      updateRecord,
      handleSaving,
      filterRecord
    }
  },
})
